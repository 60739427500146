
import logo from './logo.svg';
import logo_white from './logo_white.svg';
import hero_section_background from './media/hero-section-image.png';
import time_attendance_feature from './media/feature-time-and-attendance.png';
import resume_feature from './media/feature-resume.png';
import employee_management_feature from './media/feature-person-overview-2.png';
import icon_employee_management from './media/feature-icon-employee_management.png';
import icon_resume from './media/feature-icon-resume.png';
import icon_time_attendance from './media/feature-icon-time_and_attendance.png';
import user from './media/user.png';
import testimonial_liljewall_cecilia_windh from './media/testimonial-liljewall-cecilia-windh.png';
import liljewall_icon from './media/liljewall-logo.png';

function App() {
  return (
    <div>
      <header>
        <div className='fullWidth justify-center'>
          <div className='content-max-width fullWidth space-between content-vertically-center in-line-content'>
            <img src={logo} className="App-logo" alt="logo" />
            <div className='in-line-content'>
              {
                //<div className='margin-left-right-16'>Home</div>
                //<div className='margin-left-right-16'>Features</div>
                //<div className='margin-left-right-16'>Pricing</div> 
              }
            </div>
            <div className='in-line-content padding-rigth-16'>
              <a className='content-vertically-center' href="https://web.aone-app.com">Login</a>
              {//<div className='cta-button margin-left-16'>Sign up</div>
              }
            </div>
          </div>
        </div>
      </header>
      <div className='fullWidth'>
        <div className='fullWidth hero-section-background-color border-box justify-center'>
          <div className='fullWidth hero-section-background hero-section-layout border-box content-max-width' style={{ backgroundImage: `url(${hero_section_background})` }}>
            <div className='percent-45-width padding-left-16'>
              <div className='hero-header-text'>
                Unleash Your Team's Potential
              </div>
              <div className='hero-body-text margin-bottom-16'>
                Empower your people and elevate your business with a tool that adapt to your way of working.
              </div>
              {// <div className='cta-button'>Getting Started</div>
              }
            </div>
          </div>
        </div>

        <div className='fullWidth'>
          <div className='fullWidth justify-center section-title margin-top-32'>
            Our Features
          </div>
          <div className='fullWidth justify-center'>
            <div className='content-max-width fullWidth justify-center in-line-content content-vertically-center margin-top-32'>
              <div className='percent-55-width padding-left-16'>
                <img src={time_attendance_feature} className="fullWidth" alt="logo" />
              </div>
              <div className='percent-45-width padding-32'>
                <div className='margin-bottom-16'>
                  <img src={icon_time_attendance} className="feature-icon" alt="logo" />
                </div>
                <div className='feature-title margin-bottom-16'>
                  Time and Attendance
                </div>
                <div className='feature-content'>
                  Say goodbye to the hassle of manual time tracking and reporting.
                  A1 includes a powerful time reporting feature that streamlines the process and saves you time and effort.
                </div>
              </div>
            </div>
          </div>
          <div className='fullWidth justify-center'>
            <div className='content-max-width fullWidth justify-center in-line-content content-vertically-center margin-top-32'>
              <div className='percent-45-width padding-32'>
                <div className='margin-bottom-16'>
                  <img src={icon_employee_management} className="feature-icon" alt="employee_management" />
                </div>
                <div className='feature-title margin-bottom-16'>
                  Employee Management
                </div>
                <div className='feature-content'>
                  Managing employees can be a daunting task, but with our HRIS software, it's easier than ever. Our
                  employee management feature puts you in control, while giving your employees the power to manage their
                  own information and resolve issues.
                </div>
              </div>
              <div className='percent-55-width padding-rigth-16'>
                <img src={employee_management_feature} className="fullWidth" alt="management_feature" />
              </div>
            </div>
          </div>
          <div className='fullWidth justify-center'>
            <div className='content-max-width fullWidth justify-center in-line-content content-vertically-center margin-top-32'>
              <div className='percent-55-width padding-left-16'>
                <img src={resume_feature} className="fullWidth" alt="logo" />
              </div>
              <div className='percent-45-width padding-32'>
                <div className='margin-bottom-16'>
                  <img src={icon_resume} className="feature-icon" alt="resume" />
                </div>
                <div className='feature-title margin-bottom-16'>
                  Resume
                </div>
                <div className='feature-content'>
                  Our resume feature is incredibly easy to use. Employees can quickly create, edit, and customize their
                  resumes with just a few clicks, eliminating the need for outside assistance or costly resume-building
                  services.
                </div>
              </div>
            </div>
          </div>
        </div>

              
{/*
        <div className='fullWidth justify-center border-box padding-16 margin-bottom-32 margin-top-32'>
          <div className='fullWidth content-max-width box-container border-box'>
            <div className='fullWidth'>
              <div className='text-aligment-center testimonial-section-title'>
                Happy Client's Word
              </div>
              <div className='text-aligment-center margin-bottom-16'>
                Do not only take our word for it. Read what our customers are saying about us.
              </div>
            </div>
            <div>
              <div>
                <img src={liljewall_icon} className="client-logo margin-bottom-16" alt="client-logo" />
              </div>
              <div className='testimonial-box-container'>
                Lorem Ipsum is simple dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard is in the dummy text ever since the 1500s. Lorem Ipsum is simple dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard is in the dummy text ever since the 1500s.
              </div>
              <div className='content-vertically-center margin-top-64'>
                <span>
                  <img src={testimonial_liljewall_cecilia_windh} className="round-avatar" alt="logo" />
                </span>
                <span>
                  Cecilia Windh, Society Planner, Liljewall Architects
                </span>
              </div>
            </div>
           
            <div className='fullWidth text-aligment-center margin-top-64'>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        </div>
*/}

<br /><br /><br />

      </div>
      <footer className='fullWidth footer-background padding-top-32 padding-bottom-32'>
        <div className='fullWidth justify-center padding-bottom-32'>
          <div className='fullWidth content-max-width in-line-content space-between content-vertically-center'>
            <div className='quarter-width padding-left-16'>
              <div>
                <img src={logo_white} className="footer-logo" alt="footer-logo" />
              </div>
              <div className='footer-content'>
                Empowering people and efficiency through connected company information
              </div>
            </div>
              {/*
            <div className='quarter-width column-content'>
              <div className='footer-title margin-bottom-32'>Home</div>
              <div className='footer-content margin-bottom-16'>Features</div>
              <div className='footer-content'>Pricing</div>
            </div>
            <div className='quarter-width'>
              <div className='footer-title margin-bottom-32'>Privacy</div>
              <div className='footer-content margin-bottom-16'>Terms & Conditions</div>
              <div className='footer-content'>Legal</div>
            </div>*/
          }
          </div>
        </div>
        <div className='fullWidth justify-center footer-content'>
          <div className=''>
            Copyright © 2023 | All rights reserved
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;